<template>
  <b-card>
    <div>
      <form-wizard next-button-text="Próximo" color="#3ECE78E8" :title="null" :subtitle="null" layout="vertical"
        finish-button-text="Criar dispositivo " back-button-text="Anterior" class="wizard-vertical mb-3"
        @on-complete="createDevice">

        <tab-content title="Tipo de dispositivo" style="margin-bottom:15vw; margin-left: 6vw">
          <b-row>
            <a v-for="item in possible_devices" :key="'Device_' + item.id" style="margin-right:4rem"
              @click="changeDeviceType(item.id)">
              <b-card bg-variant="transparent" :border-variant="item.border" style="max-height: 12rem; width: 12rem;"
                text-variant="black">
                <div style="text-align: center;">
                  <b-avatar :variant="item.icon_color" size="3rem">
                    <feather-icon :icon="item.icon" size="20" />
                  </b-avatar>
                  <h4 style="margin-top:1rem; ">{{ item.title }}</h4>
                  <small>{{ item.description }}</small>
                </div>
              </b-card>
            </a>
          </b-row>
        </tab-content>


        <!-- type devices -->
        <tab-content title="Detalhes dispositivo" style="margin-bottom:10rem; margin-left: 2rem">

          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">
                Configurações do dispositivo
              </h5>
              <small class="text-muted">
                É possível alterar as configurações depois
              </small>
            </b-col>
            <b-col md="4">
              <b-form-group label="Nome" label-for="name">
                <b-form-input id="name" placeholder="Nome do dispositivo" v-model="device_name" />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <label for="tags-basic">Tag</label>
              <b-form-tags placeholder="Adicionar tag..." v-model="device_tag" input-id="tags-basic" class="mb-2" />
            </b-col>
            <b-col md="4"></b-col>
            <b-col md="4">
              <b-form-group label="Descrição" label-for="description">
                <b-form-input id="description" placeholder="Descrição do dispositivo" v-model="device_description" />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Grupo" label-for="group">
                <v-select disabled id="group" v-model="device_group" label="name" :options="get_group" />
              </b-form-group>
            </b-col>
            <b-col md="4"></b-col>
            <b-col md="4">
              <b-form-group label="Estado" label-for="stats">
                <v-select id="stats" v-model="device_status" label="name" :options="possible_status" />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Variável" label-for="variable">
                <v-select id="variable" multiple v-model="possible_devices[device_type].selected_variable" label="name"
                  :options="get_variables" />
              </b-form-group>
            </b-col>
          </b-row>
        </tab-content>
        <tab-content title=" Ícone dispositivo" style="margin-left: 2rem">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">
                Difinir ícone
              </h5>
              <small class="text-muted">
                Usado para identificação apenas
              </small>
            </b-col>
            <b-avatar variant="light-dark" size="6rem" style="margin:1rem; margin-left:2.3rem">
              <feather-icon :icon="possible_icons[selected_icon].icon" size="40" />
            </b-avatar>
            <b-col md="9">
              <b-card-group>
                <a v-for="item in possible_icons" :key="'card_' + item.id" @click="changeIcon(item.id)">
                  <b-card style="text-align:center; margin-top:1rem; margin-right: 1rem; max-width: 10rem; ">
                    <div class="icon-wrapper">
                      <b-button :variant="item.selected" class="btn-icon">
                        <feather-icon :icon="item.icon" />
                      </b-button>
                    </div>
                    <p class="card-text icon-name text-truncate mb-0 mt-1"> {{ item.text }} </p>
                  </b-card>
                </a>

              </b-card-group>
            </b-col>
          </b-row>
        </tab-content>
        <tab-content title=" Token personalizado" style="margin-bottom:18rem;" v-if="device_type == 0">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">
                Definir token personalizado
              </h5>
              <small class="text-muted">
                Coloque o token do seu dispositivo
              </small>
            </b-col>
            <b-col md="12">
              <b-form-group label="Token próprio" label-for="token">
                <b-form-input id="token" placeholder="Token do dispositivo" v-model="own_token" />
              </b-form-group>
            </b-col>
          </b-row>
        </tab-content>
      </form-wizard>

    </div>
  </b-card>
</template>
 
<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { BFormTags, } from 'bootstrap-vue'
import { BRow, BCol, BFormGroup, BFormInput, BCard, BCardText, BAvatar, BFormFile, BButton, BCardGroup } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Cookies from 'js-cookie'
import store from '@/store/index'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import api from '@/service/api'

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormTags,
    BCard,
    BCardText,
    BAvatar,
    BFormFile,
    BButton,
    ToastificationContent,
    BCardGroup,
    StatisticCardHorizontal,
    StatisticCardVertical,
  },
  async created() {
    var self = this;

    if (Cookies.get("token_api") != null) {
      store.state.token_api = Cookies.get("token_api");
    }

    if (Cookies.get('token_api') == undefined) {
      top.location.href = "/login";
    }
    if(store.state.refresh_interval == ''){
      store.state.refresh_interval = setInterval(
      api.refresh_token,
        120000
      );
      store.state.refresh_interval = true;
    }

    self.get_variables = await api.get_variables();
  },
  data() {
    return {
      own_token: '',
      selected_icon: 0,
      get_variables: [],
      device_name: '',
      device_description: '',
      device_tag: [],
      device_group: store.state.active_group,
      device_status: {
        id: 1,
        name: 'Ativo',
        value: 'true',
      },
      status: 'Ativo',

      tag: [],
      get_group: [],
      device_type: 0,
      possible_devices: [
        // {
        //   id: 0,
        //   title: 'Genérico',
        //   description: 'Configuração manual',
        //   icon: 'BoxIcon',
        //   border: 'success',
        //   icon_color: 'light-success',
        //   selected_variable: [],

        // },
        {
          id: 0,
          title: 'PinOne',
          description: 'Dados de posição',
          icon: 'WatchIcon',
          border: 'secondary',
          icon_color: 'light-primary',
          selected_variable: [
            {
              id: 7,
              name: 'Localização',
            },
            {
              id: 4,
              name: 'Bateria',
            }
          ],
        },
      ],

      possible_status: [
        {
          id: 1,
          name: 'Ativo',
          value: 'true',
        },
        {
          id: 2,
          name: 'Inativo',
          value: 'false',
        },
      ],
      possible_colors: [
        {
          id: 0,
          color: 'success'
        },
        {
          id: 1,
          color: 'secondary',
        },
      ],
      possible_icons: [
        {
          icon: 'BoxIcon',
          text: 'Cubo',
          id: 0,
          selected: 'flat-success'
        },
        {
          icon: 'BatteryIcon',
          text: 'Bateria',
          id: 1,
          selected: 'flat-darken-2'
        },
        {
          icon: 'DropletIcon',
          text: 'Humidade',
          id: 2,
          selected: 'flat-darken-2'
        },
        {
          icon: 'ThermometerIcon',
          text: 'Temperatura',
          id: 3,
          selected: 'flat-darken-2'
        },
        {
          icon: 'NavigationIcon',
          text: 'Seta',
          id: 4,
          selected: 'flat-darken-2'
        },
        {
          icon: 'Navigation2Icon',
          text: 'Seta#2',
          id: 5,
          selected: 'flat-darken-2'
        },
        {
          icon: 'MousePointerIcon',
          text: 'Mouse',
          id: 6,
          selected: 'flat-darken-2'
        },
        {
          icon: 'TruckIcon',
          text: 'Caminhão',
          id: 7,
          selected: 'flat-darken-2'
        },
        {
          icon: 'UserIcon',
          text: 'Usuário',
          id: 8,
          selected: 'flat-darken-2'
        },
        {
          icon: 'WatchIcon',
          text: 'Relógio',
          id: 9,
          selected: 'flat-darken-2'
        },
        {
          icon: 'EyeIcon',
          text: 'Olho',
          id: 10,
          selected: 'flat-darken-2'
        },
        {
          icon: 'MusicIcon',
          text: 'Música',
          id: 11,
          selected: 'flat-darken-2'
        },
        {
          icon: 'HeartIcon',
          text: 'Coração',
          id: 12,
          selected: 'flat-darken-2'
        },
        {
          icon: 'HomeIcon',
          text: 'Casa',
          id: 13,
          selected: 'flat-darken-2'
        },

      ]
    }
  },
  methods: {
    changeDeviceType(value) {
      var self = this;
      self.possible_devices[self.device_type].border = 'secondary';
      self.possible_devices[value].border = 'success';
      self.possible_devices[self.device_type].icon_color = 'light-primary';
      self.possible_devices[value].icon_color = 'light-success';
      self.device_type = value;

    },
    changeIcon(value) {
      var self = this;
      self.possible_icons[self.selected_icon].selected = 'flat-darken-2'
      self.possible_icons[value].selected = 'flat-success'
      self.selected_icon = value;
    },
    async createDevice() {
      var self = this;

      if (self.device_name == '') {
        let title = 'Erro'
        let icon = 'BellIcon'
        let text = 'O dispositivo precisa de um nome'
        let variant = 'danger'
        self.$toast({
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            variant,
          },
        })
        return;
      }
      var Id_variables = '';
      for (let i = 0; i < self.possible_devices[self.device_type].selected_variable.length; i++) {
        Id_variables += self.possible_devices[self.device_type].selected_variable[i].id

        // Verifica se é ultimo elemento
        if (self.possible_devices[self.device_type].selected_variable.length != (i + 1)) {
          Id_variables += ','
        }
      }
      var self = self;
      let data = {
        name: self.device_name,
        description: self.device_description,
        tag: String(self.device_tag),
        groupId: self.device_group.id,
        variableId: Id_variables,
        status: self.device_status.value,
        icon: self.possible_icons[self.selected_icon].id,
        deviceType: self.device_type

      };
      if (self.own_token != '') {
        data = {
          name: self.device_name,
          description: self.device_description,
          tag: String(self.device_tag),
          groupId: self.device_group.id,
          variableId: Id_variables,
          status: self.device_status.value,
          icon: self.possible_icons[self.selected_icon].id,
          idTokenDevice: self.own_token,
          deviceType: self.device_type
        }
      }
      var device = await api.post_device(data);
      if (device != 'fail') {
        store.state.selected_device = device.data.id
        let title = 'Notificação'
        let icon = 'BellIcon'
        let text = 'Dispositivo criado!'
        let variant = 'success'
        self.$toast({
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            variant,
          },
        })
        self.$router.push({ path: 'view-device' })
      } else {
        let title = 'Erro'
        let icon = 'BellIcon'
        let text = 'Vá tomar um café, e tente novamente'
        let variant = 'danger'
        self.$toast({
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            variant,
          },
        })
      }
    }
  },
}
</script>
 
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
 

